import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, timeout } from 'rxjs/operators';
import { savePrivateDayRequestModel } from "../models/save-private-day-request-model";
import { CommonUrl } from "../models/commonUrl";
import { Observable } from "rxjs";

@Injectable()
export default class Privat8eDayService {

    commonUrl = '';

    constructor(private http: HttpClient) {
        let pageUrl = window.location.toString();
        if (pageUrl.includes("https")) {
            this.commonUrl = "https://ds-kong.westeurope.cloudapp.azure.com/private-day-reminder/";
        } else {
            this.commonUrl = "http://ds-kong.westeurope.cloudapp.azure.com/private-day-reminder/";
        }
    }


    checkLinkActive(_genericKey: string): Observable<any> {
        return this.http.post(this.commonUrl + 'checkUrl', JSON.stringify({ genericKey: _genericKey })).pipe(
            timeout(30000),
            map((res) => res)
        );
    }

    getInvoiceDate(_genericKey: string): Observable<any> {
        return this.http.post(this.commonUrl + 'getInvoiceDate', JSON.stringify({ genericKey: _genericKey })).pipe(
            timeout(30000),
            map((res) => res)
        );
    }


    save(request: savePrivateDayRequestModel): Observable<any> {
        return this.http.post(this.commonUrl + 'postCustomer', JSON.stringify({
            //whoIs: request.whoIs,
            privateDate: request.privateDate,
            privateDateType: request.privateDateType,
            genericKey: request.genericKey
        })).pipe(
            timeout(30000),
            map((res) => res)
        );
    }

}
