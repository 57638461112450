import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUrl } from './models/commonUrl';
import PrivateDayService from './services/privateday.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor(private privateDayService: PrivateDayService, private router: Router) {

    // let commonUrl = new CommonUrl();
  
    // this.privateDayService.checkLinkActive(commonUrl.genericKey).subscribe(
    //   x => {
    //     if (x.success == "true") {
    //       this.router.navigate(['/privateDayReminder',commonUrl.genericKey] );
    //     } else {
    //       this.router.navigate(['/passiveLink'], {});
    //     }
    //   }
    // );

  }

}
