import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AggrementInfoComponent } from './components/aggrement-info/aggrement-info.component';
import { PassiveLinkComponent } from './components/passive-link/passive-link.component';
import { PrivateDayReminderComponent } from './components/private-day-reminder/private-day-reminder.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';

const routes: Routes = [
  { path: 'privateDayReminder/:genericKey', component: PrivateDayReminderComponent },
  { path: 'passiveLink', component: PassiveLinkComponent },
  { path: 'aggrementInfo/:genericKey', component: AggrementInfoComponent },
  { path: 'successPage', component: SuccessPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

export const RoutingComponents = [PrivateDayReminderComponent,
  PassiveLinkComponent,
  AggrementInfoComponent,
  SuccessPageComponent];
