
<div class="content" role="main" [ngStyle]="{'background-image':isMobile  ? '' :'url('+imageURL+')' }">
  <!-- Header -->
  <div class="container">
    <div class="container-fluid h-100">
      <div class="row justify-content-center align-items-center h-100" style="padding: 5%;">
        <div class="col col-sm-6 col-md-8 col-lg-6 col-xl-6"  [ngClass]="{'Rectangle': !isMobile}">

          <form>
            <div class="rectangle">
              <div class="row message">
                <div style="padding-right:10px; padding-left: 10px;">
                  <img src="./../assets/images/forbidden.png" class="Icondone-1476">
                </div>
                <div style="max-width: 75%;">
                  Üzgünüz kayıt süresi dolmuştur, yeni hediye alışverişlerinde yeniden kayıt yaptırabilirsin.
                </div>
              </div>
            </div>
            <div class="form-group" style="padding-top: 30px;">
              <button type="reset" onclick="location.href='https://www.boyner.com.tr'" class="btn btn-block"
                style="background-color: #03a87c; color: white;">
                <div class="btnText">Boyner.com.tr'yi Ziyaret Et</div>
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>