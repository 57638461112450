<div class="content" role="main">

    <div>

        <div class="container-fluid h-100" style="padding-top: 10px;">
            <div class="row justify-content-center align-items-center h-100">
                <div class="col col-sm-6 col-md-8 col-lg-6 col-xl-6">

                    <div class="modal-content" style="height:'calc(100vh - 200px)'; overflow: auto;">

                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"> Aydınlatma Metni</h5>
                            <button type="button" [routerLink]="['/privateDayReminder',genericKey]"
                                class="btn btn-danger" style="position: absolute; top:10px; right:10px;">
                                X
                            </button>
                        </div>

                        <div class="modal-body" style="position:relative;overflow-y:auto;">
                            <div data-scroll="true" data-height="300">
                                <div>
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#f22613">
                                                    <FONT FACE="avenirheavy, serif">
                                                        <FONT SIZE=5 STYLE="font-size: 21pt">Boyner
                                                            Büyük Mağazacılık A.Ş.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#f22613">
                                                    <FONT FACE="avenirheavy, serif">
                                                        <FONT SIZE=5 STYLE="font-size: 15pt">Müşteri,
                                                            Üye ve Ziyaretçi Kişisel Verilerinin Korunmasına ilişkin
                                                            Aydınlatma Metni</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Değerli
                                                            Site Ziyaretçilerimiz, Müşterilerimiz ve Üyelerimiz,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kişisel
                                                            verilerinizin işlenmesi ile ilgili uygulamalarımız ve 6698
                                                            sayılı
                                                            Kişisel Verilerin Korunması Kanunu (&quot;Kanun&quot;)
                                                            kapsamında
                                                            “Veri Sorumlusu” sıfatıyla Boyner Büyük Mağazacılık A.Ş.
                                                            (“Boyner”) olarak işlediğimiz kişisel verilerinize ilişkin
                                                            sizleri bilgilendirmek istiyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Bu
                                                            metinde:</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Boyner
                                                            mağazalarını (“Mağazalar”) ziyaret etmeniz, Mağazalar’dan
                                                            alışveriş yapmanız veya</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Boyner
                                                            Anahtar Kart programımıza üye olmanız (“Anahtar Kart
                                                            Üyesi”),</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">www.boyner.com.tr
                                                            internet sitesini (“Site”) ziyaret etmeniz veya cep
                                                            telefonunuza
                                                            indireceğiniz Boyner uygulamasını (“Mobil Uygulama”)
                                                            kullanmanız, Site/Mobil Uygulama’dan alışveriş yapmanız veya
                                                            3.kişiler tarafından yapılan alışverişin tarafınıza
                                                            gönderilmesi ya da Site’ye/Mobil Uygulama’ya üye olmanız
                                                            (“Site Üyesi”),</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ticari
                                                            iletişim kanalları ile genel ve kişiye özel kampanya
                                                            bilgilendirmeleri yapılmasını kabul etmeniz,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">İletişim
                                                            kanalları üzerinden karşılıklı olarak iletişime geçmemiz,
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kurumsal
                                                            satış süreçlerine ilişkin olarak Boyner’le iletişime
                                                            geçmeniz
                                                            ve/veya alışveriş yapmanız veya kurumsal satış süreçlerine
                                                            ilişkin olarak tarafınıza hediye,ürün vs. gönderilmesi,
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Boyner’in
                                                            düzenlemiş olduğu kampanya, yarışma, çekiliş ve diğer
                                                            etkinliklere katılmanız,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">halinde
                                                            kişisel verilerinizin kullanımına ilişkin aydınlatma metni
                                                            yer
                                                            almaktadır. Site ve Mobil Uygulama’da çerez vb. programlar
                                                            kullanılmaktadır.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#ff0000">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">1.
                                                            Kişisel Verilerinizi İşleme Amaçlarımız ve İşlediğimiz
                                                            Kişisel Verileriniz Nedir?</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Mağaza
                                                            Ziyaretleri</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Mağazalar’da
                                                            gerçekleşmesi muhtemel mevzuata aykırı her türlü fiil, suç
                                                            veya davranışa karşı siz, Boyner ve çalışanları ile diğer
                                                            üçüncü kişilerin güvenliğini sağlamak ve adli makamlara
                                                            karşı
                                                            olan bilgi, belge verme ve ilgili sair yükümlülüklerimizi
                                                            yerine
                                                            getirmek amacıyla güvenlik kamerası görüntülerinizi
                                                            işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Mağaza
                                                            Alışverişleri ve Satış sonrası Süreçler</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Alışveriş
                                                            işleminin gerçekleştirilmesi, sistemlerimizdeki size ait
                                                            kayıtların diğer müşterilere ilişkin kayıtlardan ayırt
                                                            edilebilmesi, fatura/fiş ibraz etmeksizin satış sonrası
                                                            operasyonel işlemlerden (tadilat, lostra, değişim, iade,
                                                            ürün
                                                            incelemesi, eve teslimat vs.) faydalanabilmenizi sağlamak ve
                                                            bu
                                                            operasyonel işlemlerin yerine getirilebilmesi,
                                                            alışverişinize
                                                            ilişkin e-fatura/e-arşiv faturanın tarafınıza
                                                            gönderilebilmesi,
                                                            mağazalarımızda bulunmayan ürünlerin tedarik edilerek
                                                            doğrudan
                                                            size ulaştırılabilmesi amaçlarıyla kimlik (ad soyad) ve
                                                            iletişim
                                                            bilgilerinizi (e-posta adresi, telefon numarası) ve
                                                            alışveriş
                                                            (alışveriş tarihi, miktarı, alışveriş içeriği, ödeme şekli
                                                            ve ödeme detayları) bilgilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Alışverişinizi
                                                            havalimanı mağaza(lar)ımızdan yapmanız halinde yasal
                                                            yükümlülüklerimizi yerine getirmek için bunlara ek olarak
                                                            pasaport numarası, uyruk, doğum tarihi, cinsiyet, uçuş
                                                            bilgilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Gerekli
                                                            olduğu hallerde iade süreçlerinde iadenin banka hesabınıza
                                                            yapılabilmesi için banka hesap bilgilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Fatura
                                                            düzenlenebilmesi ve bazı durumlarda cari hesap ve mutabakat
                                                            işlemlerinin yapılabilmesi amacıyla kimlik, iletişim ve
                                                            fatura
                                                            bilgilerinizi, ayrıca vergi mükellefi iseniz ek bazı fatura
                                                            bilgilerinizi (TC kimlik/ vergi numarası, şahıs şirketi
                                                            bilgileri),</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ödemenizi
                                                            kredi kartı ile yapmak istemeniz halinde ödemenin
                                                            alınabilmesi
                                                            amacıyla kredi kartı bilgilerinizi (kredi kartı bilgileri
                                                            tarafımızca kaydedilmeden ödeme kuruluşuna aktarılmaktadır.)
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ödemenizi
                                                            mail order ile yapmak istemeniz halinde ad soyad, kredi
                                                            kartı
                                                            bilgileri, telefon numarası, ürün bilgisi, sipariş tutarı,
                                                            imza
                                                            ve kimliğiniz ön yüz fotoğrafı bilgilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Tıkla
                                                            &amp; Gel sistemi ile Site veya Mobil Uygulama’dan
                                                            verdiğiniz
                                                            siparişleri Mağazalar’dan teslim almak istemeniz halinde
                                                            kimlik
                                                            (ad soyad) ve iletişim bilgilerinizi (telefon numarası,
                                                            adres) ve
                                                            alışveriş (alışveriş tarihi, miktarı, alışveriş içeriği,
                                                            siparişin teslim alınma tarihi) bilgilerinizi işliyoruz.
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Anahtar
                                                            Kart Üyeliği</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Anahtar
                                                            Kart üyelik işlemlerinizi gerçekleştirmek, Anahtar Kart
                                                            programı
                                                            uyarınca yükümlülüklerimizi yerine getirmek, kampanyalardan
                                                            faydalanmanızı sağlamak, Anahtar Kart programları arasında
                                                            hangi
                                                            programa dahil olabileceğinizi ve diğer programa geçiş için
                                                            yeterlilikleri sağlayıp sağlayamadığınızı tespit edebilmek,
                                                            üyelik bilgilendirmeleri yapabilmek, tarafınızca kazanılan
                                                            puanları, menfaatleri, indirimleri kullanmanızı ve
                                                            sorgulayabilmenizi sağlamak amaçlarıyla kimlik (ad soyad),
                                                            üyelik
                                                            numarası, iletişim (telefon numarası, e-posta, adres) ve
                                                            alışveriş
                                                            bilgileriniz (alışverişlerinizin tarihi, miktarı, alışveriş
                                                            içeriği, kazanmış olduğunuz puanlar gibi Anahtar Kart
                                                            üyeliklerine ilişkin kampanyalardan yararlanmanızı sağlayan
                                                            bilgiler)</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Genel
                                                            veya size özel kişiselleştirilmiş kampanyalar, avantajlar,
                                                            promosyonlar, reklamların oluşturulması, kampanya, yarışma,
                                                            çekiliş ve diğer etkinliklerin düzenlenmesi, segmentasyon,
                                                            raporlama, profilleme, pazarlama ve analiz çalışmalarının
                                                            yapılması, Mobil Uygulama, Site, Boyner’e ait diğer dijital
                                                            platformlar veya diğer 3. taraf ortamlarda Boyner’e ait
                                                            reklamların ve pazarlama/iletişim faaliyetlerinin (Mobil
                                                            Uygulama
                                                            ve Site’deki bildirimler, pop-up gösterimi, kişiye özel
                                                            teklifler, kullanıcı ekranlarının özelleştirilmesi,
                                                            genel/kişiselleştirilmiş reklam, arama, anket vs.) yapılması
                                                            amaçlarıyla pazarlama bilgilerinizi (doğum tarihi, cinsiyet,
                                                            il,
                                                            ilçe, demografik bilgiler, lokasyon paylaşımının açık olması
                                                            halinde lokasyon bilgisi, Site ve Mobil Uygulama içi
                                                            bildirimlere/anketlere/tekliflere/kampanyalara karşı
                                                            yaklaşımlar,
                                                            alışkanlıklar, favoriler, beğeniler, davranışlar, tercihler,
                                                            arama hareketleri, segmentler, geçmiş alışverişler, çerez
                                                            kayıtları, çerez ve reklam tanıtıcısı/kimliği bilgileri ve
                                                            cihaz ID, ödeme yöntemleri ve tercihleri, Mobil Uygulama
                                                            kullanım
                                                            süresi, Mobil Uygulama versiyon bilgisi, iletişim
                                                            tercihleri,
                                                            alışveriş tutarı, ödeme kanalları, ödemenin gerçekleştiği
                                                            banka bilgisi, kullanılan cihaza ilişkin marka, model,
                                                            teknik
                                                            özellik ve işletim sistemi bilgileri,kullandığınız operatör
                                                            bilgisi, anket cevapları vb.),</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site/Mobil
                                                            Uygulama Kullanımı ve Alışverişleri (Üye olarak veya
                                                            üyeliksiz
                                                            alışveriş)</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Alışveriş
                                                            işleminin gerçekleştirilmesi, sipariş hazırlama, paketleme,
                                                            sevk
                                                            irsaliyesi hazırlama, kargolama, teslimat bilgilerinin
                                                            güncellenmesi, siparişinizin size ulaştırılabilmesi veya
                                                            siparişinizi Tıkla&amp;Gel ile Mağazalar’dan teslim almak
                                                            istemeniz halinde Mağazalar’da uygun şekilde teslimatı
                                                            sağlama,
                                                            sistemimizde oluşturduğumuz size ait kayıtların diğer
                                                            müşterilere ilişkin kayıtlardan ayırt edilebilmesi, satış
                                                            sonrası operasyonel işlemlerin (tadilat, değişim, iade, ürün
                                                            incelemesi vs.) yerine getirilebilmesi, alışverişinize
                                                            ilişkin
                                                            e-faturanın tarafınıza gönderilebilmesi amaçlarıyla kimlik
                                                            (ad
                                                            soyad) ve iletişim bilgilerinizi (e-posta adresi, telefon
                                                            numarası,
                                                            adres) ve alışveriş (alışveriş tarihi, zamanı, miktarı,
                                                            alışveriş içeriği, ödeme şekli ve ödeme detayları)
                                                            bilgilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Fatura
                                                            düzenlenebilmesi amacıyla kimlik ve fatura bilgilerinizi,
                                                            ayrıca
                                                            vergi mükellefi iseniz ek bazı fatura bilgileriniz (TC
                                                            kimlik
                                                            numarası, vergi numarası, şahıs şirketi bilgileri)</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ödemenizi
                                                            kredi kartı ile yapmak istemeniz halinde ödemenin
                                                            alınabilmesi
                                                            amacıyla kredi kartı bilgileriniz (kredi kartı bilgileri
                                                            tarafımızca kaydedilmeden ödeme kuruluşuna aktarılmaktadır.
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">3.
                                                            kişiler tarafından Site/Mobil Uygulama üzerinden tarafınıza
                                                            ürün
                                                            gönderilmesi halinde ise siparişin tarafınıza
                                                            ulaştırılabilmesi
                                                            amacıyla ad soyad, cep telefonu ve adres bilgilerinizi
                                                            işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site
                                                            ve Mobil Uygulama’yı ziyaretiniz ve kullanımınız sırasında
                                                            karşılaşılan teknik/yazılımsal hataları, eksiklikleri tespit
                                                            edebilmek ve giderebilmek amacıyla Site ve Mobil Uygulama’yı
                                                            kullanım bilgilerinizi işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site
                                                            Üyeliği (Site/Mobil Uygulama)</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Üyelik
                                                            işlemlerinin gerçekleştirilmesini sağlamak, sizinle
                                                            akdedeceğimiz
                                                            üyelik sözleşmesinin gereğini yerine getirmek, üye girişi
                                                            yapabilmeniz, üyelik bilgilendirmesinin yapılabilmesi
                                                            amaçlarıyla
                                                            kimlik (ad soyad), iletişim (e-posta adresi, telefon
                                                            numarası) ve
                                                            gizlenmiş şekildeki parola bilgilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Herhangi
                                                            bir alışveriş kanalından alışveriş yapılmak istendiğinde her
                                                            alışveriş işleminde tekrar bilgi girilmesine gerek
                                                            kalmaksızın
                                                            üye bilgilerinizi kullanarak alışveriş yapmanızı
                                                            sağlayabilmek
                                                            amacıyla kimlik (ad soyad) ve iletişim (telefon numarası,
                                                            e-posta
                                                            adresi, teslimat ve fatura adresi bilgileri) bilgilerinizi,
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Üye
                                                            olarak tarafınıza sipariş geçmişinizi görüntüleme hizmeti
                                                            verebilmek amacıyla alışveriş bilgilerinizi (alışveriş
                                                            tarihi,
                                                            zamanı, miktarı, alışveriş içeriği, ödeme detayları),</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Üyelik
                                                            programlarından faydalanabilmeniz, Boyner’in diğer üyelik
                                                            programlarından faydalanabilmeniz, Mağazalar ve Site’den
                                                            yapacağınız alışverişlere göre oluşturulacak size özel imkan
                                                            ve tekliflerden yararlanmanız, puan kazanabilmeniz ve bu
                                                            puanları
                                                            kullanabilmeniz amacıyla Mağazalar ve Site’den yapacağınız
                                                            alışveriş bilgilerinizi, genel veya size özel
                                                            kişiselleştirilmiş
                                                            kampanyalar, avantajlar, promosyonlar, reklamların
                                                            oluşturulması,
                                                            kampanya, yarışma, çekiliş ve diğer etkinliklerin
                                                            düzenlenmesi,
                                                            segmentasyon, raporlama, profilleme, pazarlama ve analiz
                                                            çalışmalarının yapılması, Mobil Uygulama, Site, Boyner’e ait
                                                            diğer dijtial platformlar veya diğer 3. taraf ortamlarda
                                                            Boyner’e
                                                            ait reklamların ve pazarlama/iletişim faaliyetlerinin (Mobil
                                                            Uygulama ve Site’deki bildirimler, pop-up gösterimi, kişiye
                                                            özel
                                                            teklifler, kullanıcı ekranlarının özelleştirilmesi, reklam,
                                                            arama, anket vs.) yapılması, mobil cihazınızda lokasyon
                                                            paylaşımının açık olması halinde size en yakın ve uygun
                                                            tekliflerin oluşturulması ve size iletilmesi, Site ve Mobil
                                                            Uygulama üzerindeki kullanıcı deneyimini iyileştirmek
                                                            amaçlarıyla
                                                            pazarlama bilgilerinizi (doğum tarihi, cinsiyet, il, ilçe,
                                                            demografik bilgiler, lokasyon paylaşımın açık olması halinde
                                                            lokasyon bilgisi, Site ve Mobil Uygulama içi
                                                            bildirimlere/anketlere/tekliflere/kampanyalara karşı
                                                            yaklaşımlar,
                                                            alışkanlıklar, favoriler, beğeniler, davranışlar, tercihler,
                                                            arama hareketleri, segmentler, geçmiş alışverişler, çerez
                                                            kayıtları, çerez ve reklam tanıtıcısı/kimliği bilgileri ve
                                                            cihaz ID, ödeme yöntemleri ve tercihleri, Mobil Uygulama
                                                            kullanım
                                                            süresi, Mobil Uygulama versiyon bilgisi, iletişim
                                                            tercihleri,
                                                            alışveriş tutarı, ödeme kanalları, ödemenin gerçekleştiği
                                                            banka bilgisi, kullanılan cihaza ilişkin marka, model,
                                                            teknik
                                                            özellik ve işletim sistemi bilgileri,kullanılan
                                                            kullandığinız
                                                            operatör bilgisi, anket cevapları vb.), işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ticari
                                                            İletişim Süreçleri</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ticari
                                                            iletişim izni/açık rızası vermeniz halinde, Anahtar Kart
                                                            veya
                                                            Site Üyeliği kapsamında oluşturulan genel veya size özel
                                                            kişiselleştirilmiş kampanyalar, avantajlar, promosyonlar,
                                                            reklamlar, bilgilendirmeler, pazarlama faaliyetleri ile size
                                                            yönelik
                                                            ticari iletişim faaliyetlerinde (SMS, e-posta, arama vb.)
                                                            bulunulabilmesi, ürün ve hizmetlerimizle ilgili müşteri
                                                            memnuniyetine yönelik anketlerin, kampanya, yarışma,
                                                            çekiliş,
                                                            davet, açılış ve diğer etkinliklere ilişkin davetlerimizin
                                                            iletilmesi amaçlarıyla kimlik (ad soyad) ve iletişim
                                                            (telefon
                                                            numarası, e-posta adresi) bilgilerinizi işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kurumsal
                                                            Satış Süreçleri</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kurumsal
                                                            satış birimimiz ile iletişime geçmeniz halinde talebiniz
                                                            üzere
                                                            teklifinizin hazırlanması, tarafınıza iletilmesi, teklifi
                                                            kabul
                                                            etmeniz halinde bilgilerinizin ilgili mağazaya iletilmesi,
                                                            ürünlerinizin tarafınıza gönderilmesi, gerekli durumlarda ve
                                                            yeni sipariş süreçlerine ilişkin olarak iletişim kurulması
                                                            amaçlarıyla kimlik (ad soyad), iletişim (e-posta, cep
                                                            telefonu),
                                                            müşteri işlem (teklif, sipariş, kargo bilgileri), fatura
                                                            bilgilerinizi ve çalıştığınız kurum bilgilerini işliyoruz.
                                                            (Siparişinize ilişkin işlenecek bilgileriniz yukarıda mağaza
                                                            alışverişlerine ilişkin kısımda yer almaktadır.)</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">3.
                                                            kişiler tarafından kurumsal satış süreçleri kapsamında
                                                            tarafınıza hediye, ürün vs. gönderilmesi halinde ise
                                                            siparişin
                                                            tarafınıza ulaştırılabilmesi amacıyla ad soyad, cep
                                                            telefonu,
                                                            adres ve çalıştığınız kurum bilgilerini işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Çağrı
                                                            Merkezi/Müşteri İlişkileri Süreçleri</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">İletişim
                                                            kanallarımız (çağrı merkezi, e-posta, Site, Mobil Uygulama,
                                                            sosyal medya vs.) üzerinden bizimle iletişime geçtiğiniz
                                                            takdirde
                                                            bize ilettiğiniz sorun ve şikayetlerinizi çözümlemek,
                                                            gerektiğinde buna ilişkin olarak sizinle iletişime
                                                            geçebilmek
                                                            amacıyla kimlik (ad soyad) , iletişim (adres, e-posta
                                                            adresi,
                                                            telefon numarası), müşteri işlem (talep ve şikayetleriniz,
                                                            alışverişleriniz, hediye/indirim/fayda kullanımları) ve
                                                            gerekli
                                                            ise hukuki işlem bilgilerinizi; ayrıca müşteri memnuniyetine
                                                            yönelik çalışmalarımız ve aramızda doğabilecek
                                                            uyuşmazlıklarda delil olarak kullanılmak üzere çağrı merkezi
                                                            ses kayıtlarını işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Yasal
                                                            Süreçler ve Kurum içi Faaliyetler</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Mevzuattan
                                                            kaynaklanan yükümlülüklerimizi yerine getirmek, yetkili ve
                                                            görevli kamu kurum ve kuruluşlarına karşı diğer hukuki
                                                            yükümlüklerimizi yerine getirmek amaçlarıyla kimlik,
                                                            iletişim,
                                                            alışveriş, fatura ve işlem güvenliği (log kayıtları)
                                                            bilgilerinizi işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Doğacak
                                                            uyuşmazlıklarda mahkeme, icra dairesi, hakem heyeti gibi
                                                            resmi
                                                            kurum ve kuruluşlara karşı her türlü dava, cevap ve itiraz
                                                            hakkının kullanılması, uyuşmazlıklara ilişkin görüşme ve
                                                            anlaşma süreçlerinin yürütülmesi, tarafımızdan bilgi talep
                                                            etmeniz halinde gerekli bilgilerin tarafınıza
                                                            ulaştırılabilmesi
                                                            amaçlarıyla ve kurum içi denetim, iç kontrol ve raporlama,
                                                            test,
                                                            geliştirme ve iyileştirme çalışmaları kapsamında kimlik (ad
                                                            soyad), iletişim (telefon numarası, e-posta adresi, adres),
                                                            alışveriş, fatura, kamera görüntüleriniz, işlem güvenliğine
                                                            ilişkin bilgiler (Anahtar Kart üyeliği, Site üyeliği,
                                                            Site/Mobil
                                                            Uygulama alışverişleri, ticari iletişim kapsamında izin/açık
                                                            rıza/sözleşme onayına ilişkin log kayıtları) ile hukuki
                                                            işlem
                                                            bilgilerinizi (uyuşmazlık süreçlerine ilişkin yazışma ve
                                                            dosya
                                                            bilgileri) işliyoruz. Mobil Uygulama, Site ve diğer Boyner
                                                            sistemlerinin üyelik sözleşmeleri, mevzuat ve ahlaka aykırı
                                                            şekilde kullanımını engellemek, şüpheli işlemlerin ve hukuka
                                                            aykırı kullanımları tespit edebilmek, bloke etme ve bloke
                                                            kaldırma işlemlerini yapabilmek için kimlik, iletişim, kredi
                                                            kartı bilgileriniz (yalnızca ilk 6 ve son 4 hane bilgisi),
                                                            alışveriş ve sipariş bilgileriniz ile işlem güvenliği
                                                            bilgilerinizi işliyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#ff0000">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">2.
                                                            Kişisel Verilerinizi Kimlere ve Hangi Amaçla Aktarabiliriz?
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kişisel
                                                            verilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Bilişim
                                                            teknolojileri, pazarlama/reklam/analiz faaliyetleri,
                                                            lojistik
                                                            hizmetleri, operasyonel hizmetler, ödeme hizmetleri ya da
                                                            uzmanlık
                                                            gerektiren danışmanlık vb. konularda ürün ve hizmet desteği
                                                            almak amaçlarıyla şirketimizin yurtiçi ve yurtdışındaki
                                                            tedarikçiler, iş ortakları ve hizmet sağlayıcılarıyla (çağrı
                                                            merkezi, cihazlar aracılığıyla kişisel veri toplayanlar,
                                                            pazarlama/reklam/analiz hizmeti sağlayıcıları, veri tabanı
                                                            ve
                                                            sunucu hizmeti sağlayıcıları, Site ve Mobil Uygulama
                                                            kullanımlarını izleme hizmeti sağlayanlar, e-posta sunucu
                                                            hizmeti
                                                            sağlayıcıları, e-fatura ve e-arşiv fatura hizmeti
                                                            sağlayıcıları,
                                                            elektronik ileti aracı hizmet sağlayıcıları, kargo ve kurye
                                                            firmaları, kuru temizleme firmaları, terzi/tadilat/lostra
                                                            hizmeti
                                                            sağlayıcıları, depo hizmeti sağlayıcısı, banka ve elektronik
                                                            ödeme kuruluşları, hukuki ve mali danışmanlık hizmeti
                                                            verenler,
                                                            bağımsız denetim hizmeti sağlayıcıları, arşivleme hizmeti
                                                            verenler ile) veya yurt içindeki tedarikçiler, iş
                                                            ortaklarımız
                                                            ya da hizmet sağlayıcılarımızın sunucularının yurt dışında
                                                            olması nedeniyle yurtdışı ile,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Yetkili
                                                            kamu kurum ve kuruluşları ile adli makamlara karşı olan
                                                            bilgi,
                                                            belge verme ve ilgili sair yükümlülüklerimizi yerine
                                                            getirmek ve
                                                            dava ve cevap hakları gibi yasal haklarımızı kullanabilmek
                                                            amacıyla bizden istenen bilgileri anılan bu kurum, kuruluş
                                                            ve
                                                            makamlara,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Şüpheli
                                                            işlemlerin takibi, tespiti ve hukuka aykırı işlemlerin
                                                            önlenmesi
                                                            amaçlarıyla gerekli durumlarda danışmanlık hizmeti aldığımız
                                                            tedarikçilerimiz ve ilgili işleme ilişkin ödeme
                                                            kuruluşlarıyla,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Gerekli
                                                            durumlarda mağazalardaki güvenlik kamerası görüntülerinizi
                                                            mağazanın bulunduğu alışveriş merkezinin güvenlik
                                                            birimleriyle,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site
                                                            ve Mobil Uygulama’daki ziyaret trafiğinin artırılmasına
                                                            ilişkin
                                                            faaliyetler kapsamında iş ortaklarımızla,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Açık
                                                            rıza vermeniz halinde veri zenginleştirme/tekilleştirme
                                                            faaliyetlerinde bulunulması amaçlarıyla Boyner’in bağlı
                                                            olduğu
                                                            grup şirketler bünyesindeki Altınyıldız Tekstil ve
                                                            Konfeksiyon
                                                            A.Ş., BR Mağazacılık Ticaret A.Ş. (Beymen Business,
                                                            Altınyıldız
                                                            Classics), BNR Teknoloji A.Ş. (Hopi), Boyner Holding A.Ş.
                                                            ile,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Ortak
                                                            müşteri yönetim sistemi kullandığımız Boyner mağazalarını
                                                            işleten bayiler ile, paylaşmaktayız.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#ff0000">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">3.
                                                            Kişisel verilerinizin toplanma yöntemi ve hukuki sebebi
                                                            nedir?</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kişisel
                                                            verilerinizi, Boyner veya Boyner bayileri, iştirakleri,
                                                            tedarikçileri veya iş ortakları tarafından; Mağazalarda
                                                            bulunan
                                                            güvenlik kameraları ile, Mağazalarda sözlü olarak satış
                                                            danışmanlara beyan ettiğiniz bilgilerin bu kişilerce
                                                            sistemlerimize kaydedilmesi, Mağazalar, Site ya da Mobil
                                                            Uygulama
                                                            üzerinden yaptığınız alışverişlerin veya üyelik işlemlerine
                                                            ilişkin paylaştığınız bilgilerin sistemlerimize
                                                            kaydedilmesi,
                                                            sabit-mobil nitelikli internet ve iletişim cihazlarınızın
                                                            ve/veya
                                                            mobil uygulamaların muhtelif ortamlarda ve yerde
                                                            kullanımlarından
                                                            oluşan/edinilen verilerin sistemlerimize kaydedilmesi, Site
                                                            Üyesi
                                                            olmanız ve Mobil Uygulama’da lokasyon paylaşımının açık
                                                            olması ve/veya mobil cihazınızda bluetooth özelliğinin açık
                                                            olması halinde Mağazalarımızda bulunan beacon cihazları veya
                                                            Mobil Uygulama tarafından lokasyon verinizin toplanması,
                                                            çerez
                                                            politikasında detaylı olarak açıklandığı üzere Site veya
                                                            Mobil Uygulama’da çalışan çerez ve benzeri diğer
                                                            teknolojilerle, Mağaza, Site ve Mobil Uygulama üzerindeki
                                                            her türlü
                                                            alışveriş, tahsilat, teslimat, işlem, anket doldurma, kayıt
                                                            ve
                                                            davranışsal işlemlerin sistemlerimize kaydedilmesi, iş
                                                            ortaklarımızın internet sitelerinden Site ve Mobil
                                                            Uygulama’ya
                                                            yapılan yönlendirme işleminin kaydedilmesi, çağrı merkezi,
                                                            Mobil Uygulama, Site, e-posta adreslerimiz, sosyal medya ve
                                                            diğer
                                                            iletişim kanalları üzerinden karşılıklı olarak iletişime
                                                            geçilmesi sırasında bize iletmiş olduğunuz talep ve
                                                            şikayetlerinizin kaydedilmesi, çağrı merkezi ile yaptığınız
                                                            görüşmelerin kaydedilmesi, Site veya Anahtar Kart üyeliği
                                                            kapsamında açık rıza vermeniz halinde grup şirketlerimiz
                                                            olan
                                                            Altınyıldız Tekstil ve Konfeksiyon A.Ş., BR Mağazacılık
                                                            Ticaret A.Ş. (Beymen Business, Altınyıldız Classics), BNR
                                                            Teknoloji A.Ş. (Hopi), Boyner Holding A.Ş. ile paylaştığınız
                                                            verilerinizin Boyner tarafında da güncellenmesi,
                                                            zenginleştirilmesi
                                                            gibi otomatik yöntemlerle veya resmi kurum, kuruluş ve
                                                            adli/idari
                                                            makamlardan gelen sizinle ilgili bilgi/belgelerin
                                                            kaydedilmesi, mail
                                                            order formlarınızın kaydedilmesi, iade süreçlerinize ilişkin
                                                            kayıtların ilgili evrakların kaydedilmesi ile otomatik
                                                            olmayan
                                                            yöntemlerle toplamaktayız.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Kişisel
                                                            verilerinizi,</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site,
                                                            Mağazalar ve Mobil Uygulama üzerindeki satış süreçlerine
                                                            ilişkin olarak “sözleşmenin kurulması ve ifası için veri
                                                            işlemenin gerekli olması”, “hukuki yükümlülüğün yerine
                                                            getirilmesi” ile “ilgili kişinin temel hak ve özgürlüklerine
                                                            zarar vermemek kaydıyla veri sorumlusunun meşru menfaatleri
                                                            için
                                                            veri işlenmesinin zorunlu olması” hukuki sebeplerine;</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">mevzuattan
                                                            kaynaklanan saklama yükümlülüklerimize ilişkin “hukuki
                                                            yükümlülüğün yerine getirilmesi” hukuki sebebine;</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">mağazalarda
                                                            güvenlik kameraları ile kayıt yapılmasına ilişkin olarak
                                                            “ilgili kişinin temel hak ve özgürlüklerine zarar vermemek
                                                            kaydıyla veri sorumlusunun meşru menfaatleri için veri
                                                            işlenmesinin zorunlu olması” ve “hakların tesisi,
                                                            kullanılması
                                                            veya korunması için veri işlemenin ve aktarmanın zorunlu
                                                            olması”
                                                            hukuki sebeplerine;</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site
                                                            üzerindeki zorunlu, işlevsellik ve performans çerezlerine
                                                            ilişkin
                                                            olarak “ilgili kişinin temel hak ve özgürlüklerine zarar
                                                            vermemek kaydıyla veri sorumlusunun meşru menfaatleri için
                                                            veri
                                                            işlenmesinin zorunlu olması” hukuki sebebine ve
                                                            pazarlama/takip
                                                            çerezlerine ilişkin olarak ise vereceğiniz “açık rıza”
                                                            hukuki sebebine;</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Site
                                                            Üyeliği ve Anahtar Kart Üyeliği süreçlerine ilişkin olarak
                                                            ise; üyelik süreçlerine ilişkin olarak sözleşmenin kurulması
                                                            ve sözleşmeden kaynaklanan yükümlülüklerimizi yerine
                                                            getirmek
                                                            için “sözleşmenin kurulması ve ifası için veri işlemenin
                                                            gerekli olması” hukuki sebebine; Site Üyeliği ve Anahtar
                                                            Kart
                                                            Üyeliği kapsamındaki yukarıda açıklanan pazarlama
                                                            faaliyetlerine ilişkin olarak ise vereceğiniz “açık rıza”
                                                            hukuki sebebine;</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">ticari
                                                            iletişim süreçlerinde ise vereceğiniz “açık rıza”ya
                                                            dayanarak topluyoruz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">İstediğiniz
                                                            her zaman ve hiçbir gerekçe göstermeksizin açık rızanızı
                                                            geri
                                                            çekerek açık rızaya dayanan kişisel veri işleme
                                                            faaliyetlerine
                                                            son verebilirsiniz.</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#ff0000">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">4.
                                                            Haklarınız nelerdir?</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Tarafımızca
                                                            işlenen kişisel verilerinize ilişkin olarak her zaman
                                                            Kanun’un
                                                            11.maddesinde yer alan haklarınıza ilişkin tarafımıza
                                                            başvuruda
                                                            bulunabilirsiniz. Başvurularınızı Veri Sorumlusu olarak
                                                            Boyner
                                                            Büyük Mağazacılık A.Ş.’ye aşağıda belirtilen adresimize
                                                            yazılı başvuru yaparak veya sistemimizde kayıtlı bulanan
                                                            e-posta
                                                            adresinizden e-posta göndermek suretiyle iletebilirsiniz.
                                                            (İlgili
                                                            talebin Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
                                                            Tebliğ’de yer alan şartlara uygun olması gerektiğini
                                                            hatırlatmak isteriz.)</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenirheavy, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Adres:</FONT>
                                                    </FONT>
                                                </FONT>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">Büyükdere
                                                            Cad. USO Center Binası No:245 A K:B01-Z02 34396 Maslak,
                                                            Sarıyer/İstanbul</FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    <P STYLE="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 100%">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=3>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenirheavy, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">E-Posta:</FONT>
                                                    </FONT>
                                                </FONT>
                                                <FONT COLOR="#302c2c">
                                                    <FONT FACE="avenir, serif">
                                                        <FONT SIZE=2 STYLE="font-size: 10pt">&nbsp;online@boyner.com.tr
                                                        </FONT>
                                                    </FONT>
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </P>
                                    <P STYLE="margin-bottom: 0.11in"><BR><BR>
                                    </P>
                                </div>


                            </div>
                            <div class="modal-footer" style="background-color: white;">
                                <button type="button" class="btn btn-danger font-weight-bold"
                                    [routerLink]="['/privateDayReminder',genericKey]"
                                    data-dismiss="modal">Kapat</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>