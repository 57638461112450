import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aggrement-info',
  templateUrl: './aggrement-info.component.html',
  styleUrls: ['./aggrement-info.component.scss']
})
export class AggrementInfoComponent implements OnInit {

  genericKey: string;
  constructor(private router: ActivatedRoute) {
    this.genericKey = this.router.snapshot.paramMap.get('genericKey');
  
  }

  ngOnInit(): void {
  }

}
