<div class="toolbar" >
  <img src="../../../assets/images/ui-kit-logo-boyner.png" srcset="../../../assets/images/ui-kit-logo-boyner@2x.png 2x,
   ../../../assets/images/ui-kit-logo-boyner@3x.png 3x" class="UIKitLogoBoyner">
</div>


<div class="Hediyeyi-Semek-Send">
  Hediyeyi Seçmek Senden,
  Hatırlatmak Bizden
</div>
